<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>用户列表</span>
      </div>
      <div class="right">
        <el-input v-model="keyword" placeholder="请输入搜索..."></el-input>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button @click="add">添加</el-button>
      </div>
    </div>
    <el-table :data="list" row-key="id">
      <el-table-column
        prop="u_name"
        label="名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="u_account"
        label="账号"
        align="center"
      ></el-table-column>
      <el-table-column prop="u_status" label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.u_status ? "正常" : "禁用" }}
        </template>
      </el-table-column>
      <el-table-column prop="goods_name" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button size="small" type="danger" @click="handleDel(row.u_id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
    <el-dialog title="添加账户" :visible.sync="showAdd" width="600px">
      <el-form label-width="80px">
        <el-form-item label="名称：">
          <el-input v-model="addForm.u_name"></el-input>
        </el-form-item>
        <el-form-item label="账号：">
          <el-input v-model="addForm.u_account"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input v-model="addForm.u_password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="角色：">
          <el-select
            v-model="addForm.role_id"
            filterable
            placeholder="请选择角色"
          >
            <el-option
              v-for="item in rolesList"
              :key="item.role_id"
              :label="item.role_name"
              :value="item.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-radio-group v-model="addForm.u_status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
export default {
  data() {
    return {
      page: 1,
      rows: 10,
      keyword: "",
      list: [],
      total_number: 0,
      showAdd: !1,
      type: 0,
      addForm: {
        u_name: "",
        u_password: "",
        u_account: "",
        role_id: "",
        u_status: 1,
      },
      rolesList: [],
    };
  },
  created() {
    this.getList();
    this.getRoles();
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      if (this.name) data.name = this.name;
      this.$axios.post(this.$api.user.getAll, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取角色
    getRoles() {
      this.$axios
        .post(this.$api.RolesDep.getAll, { rows: 1000 })
        .then((res) => {
          if (res.code == 0) {
            this.rolesList = res.result.list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleDel(id) {
      this.$confirm("确认删除此账户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(this.$api.user.del, {
            id: id,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("删除成功");
              this.list = this.list.filter((item) => item.u_id != id);
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    handleEdit(row) {
      row = JSON.parse(JSON.stringify(row))
      this.type = 1;
      for (let i in row) {
        for (let y in this.addForm) {
          if (y == i) this.addForm[y] = row[i];
        }
      }
      this.addForm.role_id = Number(row.role_id)
      this.addForm.u_id = row.u_id;
      this.addForm.u_password = '';
      this.showAdd = !0;
    },
    add() {
      this.showAdd = !0;
      this.type = 0;
    },
    //确认添加
    comfirmAdd() {
      let url = this.type ? this.$api.user.update : this.$api.user.add;
      if(this.type){
        if(!this.addForm.u_password) delete this.addForm.u_password
      }
      this.$axios.post(url, this.addForm).then((res) => {
        if (res.code == 0) {
          this.showAdd = !1;
          this.$message.success(this.type ? "编辑成功" : "添加成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      .el-input {
        width: 270px;
        margin-right: 20px;
        /deep/ .el-input__inner {
          border-radius: 19px;
          margin-right: 20px;
        }
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409EFF;
      }
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
  .el-form-item {
    text-align: left;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
